import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import ConfigsContext from '../../contexts/configs';
import { Announcement } from '../../types/Config/Announcements';

const Modal = dynamic(() => import('../Modal').then((mod) => mod.Modal));

const GlobalAnnouncement: React.FunctionComponent<
  React.PropsWithChildren
> = () => {
  const { announcements } = useContext(ConfigsContext);
  const [announcement, setAnnouncement] = useState<Announcement>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const announcementFoundBasedOnUrlParams = announcements.announcements.find(
      (announcement) => {
        const param = params.get(announcement.param);

        return param === announcement.value;
      },
    );
    setAnnouncement(announcementFoundBasedOnUrlParams);
  }, [announcements]);

  if (!announcement) return null;

  return (
    <Modal
      title={announcement?.title}
      content={announcement?.message}
      isShown={!!announcement}
      onClose={() => {
        setAnnouncement(undefined);
        window.history.replaceState({}, document.title, location.pathname);
      }}
      dangerouslySetContent
    />
  );
};

export default GlobalAnnouncement;
